.date-picker-styles {
  background-color: #1f2a40;
  height: 34px;
  margin: 12px 2px;
  width: 220px;
  // border: 1px solid gray !important;
  color: red;
  label {
    margin: -2%;
    color: white;
  }
}

.date-picker-styles input[type="text"] {
  background-color: #1f2a40;
  border: 0px;
}
