@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

* {
  padding: 0;
  margin: 0;
}
body {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Tajawal', sans-serif;
    zoom: 0.9;
}

.App {
  display: flex;
  background: url("../public/faqpage2.png") ;
  background-color: black;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 110vh;
  overflow-y: auto;
}

main {
  width: 100%;
  height: 111vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* New bellow
  display: flex;
    justify-content: flex-start;
    flex-direction: row; */
}
.rdrDateRangePickerWrapper {
  z-index: 99;
  position: absolute;
  right: 15%;
  top: 16%;
}
.MuiSvgIcon-fontSizeSmall {
  color: white;
  width: 3rem !important;
  height: 3rem !important;
}
.MuiTabs-flexContainer {
  justify-content: center;
}

/* .MuiMenu-paper {
  background: black !important;
  left: 75% !important;
  top: 8% !important;
  max-width: 20% !important;
  width: 20% !important;
}
@media only screen and (max-width: 667px) {
.MuiMenu-paper {
  background: black !important;
  left: 0 !important;
  top: 7% !important;
  max-width: 100% !important;
  width: 100% !important;
}} */