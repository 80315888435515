// .MuiToggleButton-primary {
//   background-color: rgb(118, 118, 118) !important;
//   color: #d8d8d8 !important;
// }
// .Mui-selected {
//   background-color: rgb(73, 73, 73) !important;
//   color: rgb(255, 255, 255) !important;
// }
.trade-market-container {
  // background: #07232e;
  // border-radius: 3px;
  // border: 1px solid rgba(255, 255, 255, 0.3);
  // box-shadow: 0px 1px 0px 1px rgb(141, 131, 131);
  width: 25%;
  padding: 0 20px;
}
.trade-symbol-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trade-symbol {
  color: white;
}

.react-form-container {
  color: #fff;
}
.react-from {
  width: 250px !important;
}
.form-wrap {
}

.form-group {
  // background-color: gray;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}

.leverageOptions {
  width: 100%;
  height: 42px;
  padding: 12px 12px;
  outline: none;
}

.button-group {
  display: flex;
  justify-content: space-evenly;
  margin: 12px 0;
  color: white;
}
.button-buy {
  width: 120px;
  padding: 10px;
  color: white;
  font-size: 16px;
  background-color: rgb(0, 161, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.button-sell {
  width: 120px;
  padding: 10px;
  color: white;
  font-size: 16px;
  background-color: red;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.limit-order-info {
  margin-bottom: 12px;
  font-size: 0.8rem;
}
.limit-order-row {
  display: flex;
  justify-content: space-between;
}
.limit-order-title {
  color: white;
}
.limit-order-profit {
  color: rgb(0, 161, 0);
}
.limit-order-loss {
  color: red;
}

input[type="text"] {
  // background-image: url(../../../../../public/bitcoin.svg);
  background-position: 2px 0px;
  background-repeat: no-repeat;

  position: relative;
  width: 100%;
  padding: 12px;
  // padding-left: 60px;
  margin: 4px 0;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid rgba(226, 232, 245, 0.2);
}
input[type="text"]:focus {
  outline: none;
  //background-color: rgb(224, 247, 255);
}
i {
  position: absolute;
  top: 20;
  left: 0;
}
