.edit-withdraw-wrap {
  position: absolute;
  z-index: 999;
  padding: 20px 20px 20px 20px;
  background-color: #141b2d;
  width: 400px;
  display: flex;
  height: 350px;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0rem 0rem 200rem 200rem #0000006e;
  margin: 0 auto;
  inset: 0;

  .edit-withdraw-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .edit-withdraw-modal-info {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
  }
  .edit-withdraw-top {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .edit-withdraw-buttons-group {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;
    button {
      font-size: 12px;
    }
  }

  .edit-withdraw-textField input[type="text"] {
    padding: 12px;
    margin: 12px;
    background-color: #1f2a40;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .edit-withdraw-button-wrap {
    display: flex;
    justify-content: center;
  }
}
