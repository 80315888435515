.comparison {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  font: 18px "Avenir Next", Roboto, Helvetica, Arial, sans-serif;
  color: #4b4b4b;
  text-align: center;
  padding: 8px 10px 75px 10px;
}
.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border-bottom: 1px solid #e6e6e6;
}
.comparison td,
.comparison th {
  border-right: 1px solid #e6e6e6;
  empty-cells: show;
  padding: 16px;
}
.compare-heading {
  font: 18px "Avenir Next", Roboto, Helvetica, Arial, sans-serif;
  color: #4b4b4b;
  font-weight: bold !important;
  border-bottom: 0 !important;
  padding-top: 10px !important;
}
.comparison tbody tr:nth-child(odd) {
  display: none;
}
.comparison .compare-row {
  border: 1px solid #e6e6e6;
  color: rgb(211, 211, 211);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.comparison .fa-check {
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
}
.comparison .fa-remove {
  color: #ffffff;
  font-size: 18px;
}
.comparison .fa-info-circle {
  color: #cccccc;
  font-size: 20px;
}
.comparison th {
  font-weight: normal;
  padding: 0;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  color: #757575;
}
.comparison tr td:first-child {
  text-align: center;
  background: #15495e;
  border: 1px solid #e6e6e6;
}
.comparison .qbse,
.comparison .qbo,
.comparison .tl {
  color: #4a4a4a;
  padding: 10px;
  font: 16px "Avenir Next Demi", Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  border-right: 1px solid #e6e6e6;
  border-bottom: 0;
}
.comparison .tl2 {
  border-right: 0;
}
.comparison .qbse {
  background: #0078c1;
  border-top-left-radius: 3px;
  border-left: 0px;
}
.comparison .qbo {
  background: #15495e;
  border: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  color: rgb(211 211 211);
  font-size: 22px;
}
.comparison .price-info {
  padding: 10px 15px 15px 15px;
}
.comparison .price-now,
.comparison .price-now span {
  color: #fcc308;
}
.comparison .price-now span {
  color: #fcc308;
  font-size: 36px;
  font-weight: 400;
  margin: 0px -6px 0px 0px;
}
.comparison .price-now-small-span {
  color: rgb(249, 235, 207);
  font-weight: 400;
  margin: 0px -6px 0px 0px;
}
.comparison .price-small {
  font-size: 20px !important;
  font-family: "Avenir Next Demi", Roboto, Helvetica, Arial, sans-serif;
  position: relative;
  top: -11px;
  left: -7px;
}
.comparison .price-buy {
  --background: #000;
  padding: 12px 12px;
  display: inline-block;
  min-width: 160px;
  --color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", arial, sans-serif;
  letter-spacing: 0.7px;
  --border: 1px solid rgb(0, 0, 0);
}
.comparison .monthly-cta {
  background: #fff;
  color: #000;
  border: 1px solid rgb(0, 0, 0);
}
.comparison .annual-cta {
  background: #000;
  color: #fff;
  border: 1px solid rgb(0, 0, 0);
}
.comparison .monthly-cta:hover {
  background: #d6d3eb;
  color: rgb(0, 0, 0);
  border: 1px solid #d6d3eb;
}
.comparison .annual-cta:hover {
  background: #d6d3eb;
  color: rgb(0, 0, 0);
  border: 1px solid #d6d3eb;
}
.comparison .price-try {
  font-size: 12px;
}
.comparison .price-try a {
  color: #202020;
}
@media (max-width: 767px) {
  .comparison {
    font-size: 14px;
    padding: 0;
  }
  .comparison .qbo {
    background: none;
  }
  .comparison td:first-child,
  .comparison th:first-child {
    display: none;
  }
  .comparison tbody tr:nth-child(odd) {
    display: table-row;
    background: #f7f7f7;
  }
  .comparison .row {
    background: #fff;
  }
  .comparison td,
  .comparison th {
    border: 1px solid #e6e6e6;
  }
  .price-info {
    border-top: 0 !important;
  }
}
@media (max-width: 639px) {
  .comparison .price-info {
    padding: 10px 5px 10px 5px;
  }
  .comparison .price-buy {
    padding: 12px 10px;
    letter-spacing: 0.6px;
    font-size: 12px;
    min-width: 0px;
  }
  .comparison td,
  .comparison th {
    padding: 10px 5px;
  }
  @media only screen and (max-width: 600px) {
    .price-now-small-span {
      display: none;
    }
  }

  .comparison .price-now span {
    font-size: 24px;
    margin: 0px -2px 0px 0px;
  }
  .comparison .price-small {
    font-size: 24px !important;
    top: 0;
    left: -0.5;
  }
  .comparison .qbse,
  .comparison .qbo {
    font-size: 16px;
    padding: 10px 5px;
  }
  .comparison .price-buy {
    margin-top: 16px;
  }
  .compare-heading {
    font-size: 10px !important;
  }
  .comparison th {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .comparison {
    padding: 0;
  }
  .comparison td,
  .comparison th {
    font-size: 10px !important;
  }
  .comparison .fa-check {
    font-size: 10px !important;
  }
  .compare-row td {
    font-size: 10px !important;
  }
  /* Add a pseudo element with the text from attribute 'data-abbr' to shorten the CTA text */
  #choose-plan-cta[data-abbr]::after {
    content: attr(data-abbr);
  }
  /* Hide the original CTA text */
  #choose-plan-cta > span {
    display: none;
  }
}
.tooltip {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 2px solid #fff;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
}
.comparison .fa-info-circle:hover {
  -webkit-animation-play-state: paused;
}
.comparison .fa-info-circle:hover .tooltip-info {
  visibility: visible;
}
.comparison .fa-info-circle:active {
  -webkit-animation-play-state: paused;
}
.comparison .fa-info-circle:active .tooltip-info {
  visibility: visible;
}
.tooltip-info {
  width: 200px;
  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  position: absolute;
  visibility: hidden;
  margin: -130px 0 0 -100px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
}
.tooltip-title {
  font-family: "Avenir Next Demi", Roboto, Helvetica, Arial, sans-serif;
  color: #4b4b4b;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  margin: 0 0 5px 0;
}
.tooltip-text {
  font-family: "Avenir Next", Roboto, Helvetica, Arial, sans-serif;
  color: #4b4b4b;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
.tooltip-arrow {
  position: absolute;
  margin: 10px 0 0 88px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}
