.affiliate-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  color: rgb(225, 230, 225);
  background: #1f2a40;
}
.affiliate-create-wrap {
  margin-top: 12px;
}
.affiliate-create-button {
  border: none;
  outline: none;
  padding: 10px;
  margin-top: 8px;
}
.affiliate-info {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.affiliate-info-single {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  text-align: center;
  .left-p {
    width: 200px;
    text-align: left;
  }
  .right-p {
    width: 200px;
    text-align: right;
  }
}
.affiliate-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  margin: 0 auto;
}
