.platform-page-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  background: url("../../../public/ass2.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160vh;
  padding-bottom: 5%;
}

.platform-page-video-container {
  visibility: visible;
  left: 0px;
  top: 0px;
  transform-origin: left top;
  transform: scale(0.649145);
  opacity: 1;
  height: 0;
}
.platform-page-header {
  display: flex;
  margin-top: 50px;
  margin-left: 8%;
  font-size: 60px;
  font-weight: bolder;
  color: #648eb3 !important;
  filter: blur(1px);
}

.platform-page-textBox {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-left: 3%;
  background: #0000006e;
  padding: 1rem;
  margin-top: 3rem;
  border-radius: 2rem 2rem 2rem 2rem;
  width: 90%;
}
.platform-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}

.platform-page-span {
  width: 80%;
  margin-top: 30px;
  color: #d2d2d2;
  font-size: 24px;
  // font-family: "Open Sans", sans-serif;
  text-shadow: 1px 1px 4px black;
  line-height: 1.5;
}

.platform-page-imagebox {
  margin-right: 100px;
  z-index: 99;
  display: none;
  width: 45%;
}

.platform-page-imagebox2 {
  width: 100%;
  margin-top: 3rem;
  margin-right: 2rem;
  img {
    width: 700px;
  }
}

.platform-page-reveal-container {
  width: 60%;
}
@media only screen and (max-width: 800px) {
  .platform-page-container {
    margin: 0;
    padding-bottom: 50%;
    height: 180vh;
  }
  .platform-page-inner-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .platform-page-reveal-container {
    width: 100%;
  }
  .platform-page-header {
    font-size: 2.5rem;
  }

  .platform-page-crypto-chart-container {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    padding-left: 0 !important;
  }
  .platform-page-imagebox2 {
    display: none;
  }
  .platform-page-h3 {
    text-align: center;
    width: 90%;
    font-size: 21px;
    margin-top: 2rem;
    margin-bottom: 20px;
  }

  .platform-page-textBox {
    width: 100%;
    text-align: center;
    font-size: 22px;
    z-index: 10;
    margin-left: 0;
    margin-top: 55%;
    .platform-page-span {
      font-size: 18px;
      text-align: center;
      z-index: 11;
      width: 90%;
      margin-top: 50px;
    }
  }
}
