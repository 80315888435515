.deposit-page-root-container-parent-root {
  background: rgba(0, 0, 0, 0.634) url("../../../public/backgorund.jpg")
    no-repeat center center fixed;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 120vh;
  overflow-y: auto;
}
.deposit-page-root-container-parent {
  width: 100%;
  background: #131722;
  position: relative;
  height: 400px;
  z-index: 9999;

  .deposit-page-root-container {
    width: 70%;
    height: 75%;
    margin: 0 auto;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background-color: #07232e;
    padding-top: 15px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid rgb(83, 83, 83);
    padding-left: 1rem;
    padding-right: 1rem;

    .deposit-page-container-header {
      height: 5%;
      text-align: left;
      color: white;
      font-size: 14;
      border-bottom: 1px solid rgba(226, 232, 245, 0.1);
      padding-bottom: 10px;
      width: 100%;
      margin-bottom: 40px;
    }
    .wallet-page-information-wrapper {
      width: 60%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .MuiFormControl-root {
        align-items: center;
        .MuiTypography-root {
          color: white !important;
        }
        label {
          color: white;
        }

        .before-component {
          color: white !important;
        }

        .MuiInputLabel-root {
          &::before {
            color: black !important;
          }
        }
        .MuiInput-root {
          //   color: white;
          margin-bottom: 20px !important;
          &:focus {
            outline: none;
            border: none;
            border-bottom: 0.1rem solid #08b786;
          }

          &:after {
            border-bottom: 2px solid #08b786;
          }
        }

        .wallet-page-payment-method-button {
          margin-top: 30px !important;
          background-color: #08b786 !important;
          &:hover {
            background-color: #087959 !important;
          }
        }
      }
    }
    .deposit-page-payment-gate-container {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      height: 100%;
    }
    .deposit-page-payment-method-header {
      width: 90%;
      text-align: left;
      color: white;
      font-size: 24px;
      // margin-bottom: 50px;
    }

    .deposit-page-credit-card-container-active {
      height: 70%;
      color: black;
      width: 40%;
      padding-left: 2rem;
      border: 1px solid rgb(83, 83, 83);
      padding-top: 1.5rem;
      background-color: #08b786;
      border-radius: 10px;
      text-align: center;
      padding-left: 0;
      opacity: 1;
      transition: 1s ease;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("../../../public/dark-logo.svg");
      @media only screen and (max-width: 667px) {
        width: 85%;
      }
      .MuiSvgIcon-root {
        font-size: 45px;
        color: white;
        margin: 0 auto;
      }

      h5 {
        font-size: 15px;
        color: white;
      }
    }

    .deposit-page-credit-card-container {
      height: 70%;
      color: white;
      width: 40%;
      border: 1px solid rgb(83, 83, 83);
      padding-top: 1.5rem;
      background-color: #0d6372;
      border-radius: 10px;
      text-align: center;
      padding-left: 0;
      opacity: 0.5;
      transition: 1s ease;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("../../../public/dark-logo.svg");
      @media only screen and (max-width: 667px) {
        width: 80%;
      }
      &:hover {
        opacity: 1;
        transition: 1s ease;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 45px;
          color: white;
          margin: 0 auto;
        }

        h5 {
          font-size: 15px;
          color: white;
        }
      }

      .MuiSvgIcon-root {
        font-size: 45px;
        color: grey;
        margin: 0 auto;
      }

      h5 {
        font-size: 15px;
        color: grey;
      }
    }
  }
}

.deposit-page-root-container-parent-active {
  width: 100%;
  background: #131722;
  position: relative;
  height: 100vh;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .deposit-page-root-container-active {
    width: 70%;
    height: 75%;
    margin: 0 auto;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background-color: #07232e;
    padding-top: 15px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid rgb(83, 83, 83);
    padding-left: 1rem;
    padding-right: 1rem;
    .wallet-page-information-wrapper {
      width: 20%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media only screen and (max-width: 667px) {
        width: 80%;
      }
      .MuiFormControl-root {
        .MuiTypography-root {
          color: white !important;
        }
        label {
          color: rgba(255, 255, 255, 0.563);
          font-size: 1rem;
        }

        .before-component {
          color: white !important;
        }

        .MuiInputLabel-root {
          &::before {
            color: white !important;
          }
        }
        .MuiInput-root {
          color: white;
          margin-top: 6%;
          font-size: 0.9rem;
          margin-bottom: 2% !important;
          &:focus {
            outline: none;
            border: none;
            border-bottom: 0.1rem solid #08b786;
          }

          &:after {
            border-bottom: 2px solid #08b786;
          }
        }
      }
      .wallet-page-payment-method-button {
        margin-top: 10px !important;
        background-color: #08b786 !important;
        &:hover {
          background-color: #087959 !important;
        }
      }
      .date-picker-styles {
        background-color: transparent;
        margin-top: 6%;
        height: 34px;
        border: 1px solid rgba(226, 232, 245, 0.2);
        // border: 1px solid gray !important;
        color: red;

        label {
          color: white;
          color: rgba(255, 255, 255, 0.563);
          left: -4%;
        }
      }

      .date-picker-styles input[type="text"] {
        background-color: transparent;
        border: 0px;
      }
    }

    .depositInput {
      width: 30%;
      @media only screen and (max-width: 667px) {
        width: 50%;
      }
    }
    .depositButton {
      width: 15%;
      height: 30px;
      @media only screen and (max-width: 667px) {
        width: 20%;
      }
    }
    .depositForm {
      display: flex;
      flex-direction: column;
    }
  }

  .deposit-page-container-header-active {
    height: 5%;
    text-align: left;
    color: white;
    font-size: 14;
    border-bottom: 1px solid rgba(226, 232, 245, 0.1);
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 40px;
  }

  .deposit-page-payment-method-header-active {
    width: 90%;
    text-align: left;
    color: white;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .MuiFormControl-root {
    .MuiTypography-root {
      color: white !important;
    }
    label {
      color: white;
    }

    div {
      color: white;
    }

    .before-component {
      color: white !important;
    }

    .MuiInput-root {
      color: black;

      &:focus {
        outline: none;
        border: none;
        border-bottom: 0.1rem solid #08b786;
      }

      &:after {
        border-bottom: 2px solid #08b786;
      }
    }

    .deposit-page-payment-method-button {
      width: 20% !important;
      margin-top: 30px;
      background-color: #08b786 !important;

      &:hover {
        background-color: #087959 !important;
      }
    }
  }
}
