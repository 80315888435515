.mobileprivacy-policy-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  align-items: center;
}

.mobileprivacy-policy-page-header {
  // font-family: "Press Start 2P", cursive;
  color: #648eb3 !important;
  filter: blur(1px);
  font-weight: bolder;
  margin: 15% auto;
  position: relative;
  font-size: 35px;
  left: 0;
}
.mobileprivacy-policy-page-textBox {
  width: 90%;
  background-color: #648eb3;
  margin-top: 0;
}

.mobileprivacy-policy-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}
.mobileprivacy-policy-page-span {
  font-size: 18px;
  // font-family: "Open Sans", sans-serif;
  color: black;
  text-shadow: 0px 0px 1px black;
  text-align: center;
}

@media only screen and (max-width: 667px) {
  .mobileprivacy-policy-page-textBox {
    width: 90%;
    background-color: #648eb3;
    margin-top: 0;
  }
  .mobileprivacy-policy-page-header {
    margin: 15% auto;
    position: relative;
    font-size: 35px;
    left: 0;
  }
}
