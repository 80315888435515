html {
}
.main {
	display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px
}

.firm-page-header {
    display: flex;
    margin-top: 50px;
    margin-left: 8%;
    font-size: 60px;
    font-weight: bolder;
    color: #648eb3 !important;
    filter: blur(1px);
    margin-bottom:10px
}

.page-title {
	color: var(#3b3b98);
	font-size: 4rem;
	margin-bottom: 5rem;
	margin-top: 0;
}

.leadership {
	display: grid;
	// grid-template-rows: repeat(2, 1fr);
	grid-template-columns: 1fr;
	gap: 12rem;
    margin-top: 20px;
    align-items: center;
    margin: 20px auto;
    justify-content: space-around;
     @media (min-width: 32em) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 50em) {
		grid-template-columns: repeat(3, 1fr);
	}

	.leader {
		width: 100%;
		box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
		@media (min-width: 32em) {
			max-width: 300px;
		}
		
		&:hover {
			cursor: pointer;

			.leader__bio {
				background-color: var(#b33771);
			}
			.leader__img img {
				transform: scale(110%);
				filter: saturate(100%);
			}
			.leader__name {
				color: var(#3b3b98);
			}
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 80px;
			background: var(#3b3b98);
			color: #fff;
		}
		&__exp {
			display: flex;
			align-items: center;
			font-size: 1.5rem;
			padding: 1rem 2rem;
			.years {
				font-size: 3rem;
				margin-right: 8px;
				font-weight: 700;
			}
			.span-next-to-years {
				font-size: 1rem;
				width: 10rem;
			}
		}
		&__bio {
			padding: 1.5rem 2rem;
			font-size: 2.5rem;
			text-decoration: none;
			color: var(#fff);
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			background: var(#182c61);
			transition: 0.2s all ease-in-out;
		}
		&__img {
			overflow: hidden;
			width: 100%;
			height: 300px;
			position: relative;
			img {
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				text-align: center;
				width: 100%;
				filter: saturate(0%);
				transform: scale(130%);
				transition: 0.2s all ease-in-out;
			}
		}
		&__name {
			text-align: center;
			padding: 1.5rem;
			font-size: 2rem;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: 0.2s all ease-in-out;
			color: var(#2c3a47);
			.leader-name-h2 {
				font-size: 1.5rem;
			}
			.leader-name-p {
				font-size: 1rem;
			}
			@media (min-width: 32em) {
				font-size: 1.5rem;
			}
			@media (min-width: 50em) {
				font-size: 2rem;
			}

			h2 {

				@media (min-width: 32em) {
					font-size: 2rem;
				}
				@media (min-width: 50em) {
					font-size: 2.5rem;
				}
			}
		}
	}
}
