.comment-user-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.716);
  z-index: 999;
  padding: 20px 220px 20px 20px;
  height: 1100px;
}
.comment-user-modal {
  display: flex;
  flex-direction: column;
  margin: 20% auto;
  align-items: center;
}
.comment-user-modal-info {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 15%;
  padding: 20px;
  background: #141b2d;
  border-radius: 12px;
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
}
.comment-user-top {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
