.transactions-page-root-container-parent-root {
  background: rgba(0, 0, 0, 0.634) url("../../../public/backgorund.jpg")
    no-repeat center center fixed;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 120vh;
  overflow-y: auto;
}
.transactions-page-root-container-parent {
  width: 100%;
  background: #131722;
  position: relative;
  height: 600px;
  z-index: 9999;

  .transactions-page-root-container {
    width: 70%;
    height: 75%;
    margin: 0 auto;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background-color: #07232e;
    padding-top: 15px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid rgb(83, 83, 83);
    padding-left: 1rem;
    padding-right: 1rem;

    .transactions-page-container-header {
      height: 5%;
      text-align: left;
      color: white;
      font-size: 14;
      border-bottom: 1px solid rgba(226, 232, 245, 0.1);
      padding-bottom: 10px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
