.admin-dashboard {
  background-color: #141b2d;
  position: relative;
  top: 0;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  // overflow-y: scroll;
  // overflow-x: hidden;

  // left: 5%;
  // width: 95%;
  height: 100%;

  .admin-dashboard-cards-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-around;
    .admin-dashboard-card {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 20%;
      height: 80px;
      align-items: center;
      font-size: 1rem;
      color: rgb(225, 221, 221);
      background: #1f2a40;
    }
  }
  .admin-dashboard-tables {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 2%;
  }
}
