.paragraph {
  line-height: 1.5;
  margin: 30px 0;
}

.hiring-section {
  height: 48rem;
  margin-top: 5rem;
  background: linear-gradient(to right, #08b786 30%, rgb(100 100 100 / 51%));
  color: white;
  line-height: 1.5;
  // font-family: "Nunito", sans-serif;
  position: relative;
}

@media (min-width: 600px) {
  .hiring-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .hiring-background {
    padding: 3vw 0 3vh 3vw;
  }

  .hiring-image-wrapper {
    filter: hue-rotate(20deg);
    clip-path: polygon(0% 100%, 50% 0%, 100% 0%, 100% 100%);
  }
}

.hiring-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  h2 {
    font-weight: 600;
    white-space: nowrap;
    font-size: clamp(2rem, 5vw, 4rem);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: clamp(1rem, 1.5vw, 3rem);
  }
}

.hiring-image-wrapper {
  height: 100%;
  width: 100%;
  clip-path: polygon(0%, 0%, 0% 0%);

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right center;
  }
}

.hiring-container {
  font-size: 1.2rem;
}
.hiring-rows {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 50% 50%;
  grid-template-columns: 40% 40%;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 20rem;
}

.hiring-job-preview a {
  color: #31383d;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.hiring-job-preview a:hover,
a:focus {
  color: #73a5fc;
}

.hiring-main-container {
  z-index: 10;
  background: linear-gradient(to right, #212121 30%, #08b786);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-family: "Hind", sans-serif;
    font-weight: 800;
  }
}
ul.hiring-job-list {
  margin: 1rem;
  padding: 1rem;
  list-style: none;
}

ul.hiring-job-list > li.hiring-job-preview {
  background: #fff;
  border: 1px solid #d7e2e9;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  float: left;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

ul.hiring-job-list > li.hiring-job-preview:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.hiring-job-title {
  margin-top: 0.6rem;
}

.hiring-company {
  color: #96a4b1;
}

.hiring-job-preview .btn {
  margin-top: 1.1rem;
}

.hiring-btn-apply {
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  background-color: transparent;
  color: #393a5f;
  border: 2px solid #393a5f;
  padding: 0.3rem 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  width: 50%;
  height: 10%;
  font-size: 1rem;
  cursor: pointer;
}

.hiring-btn-apply:hover {
  background-color: #393a5f;
  color: #fff;
  border: 2px solid #393a5f;
}

@media (max-width: 575px) {
  .job-preview .content {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  #navbar-main {
    background: transparent;
    border-bottom: 1px solid transparent;
  }

  #navbar-main .navbar-brand {
    color: #fff;
    opacity: 0.8;
    padding: 0.95rem 1.2rem;
  }

  #navbar-main .navbar-brand:hover,
  #navbar-main .navbar-brand:focus {
    opacity: 1;
  }

  #navbar-main .navbar-nav > li.nav-item > a {
    color: #fff;
    opacity: 0.8;
    padding: 0.95rem 1.2rem;
  }

  #navbar-main .navbar-nav > li.nav-item > a:hover,
  #navbar-main .navbar-nav > li.nav-item > a:focus {
    opacity: 1;
  }
}

@media only screen and (max-width: 667px) {
  .hiring-rows {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin-top: 60%;
  }
}
