.advisers-container {
    margin-top: 50px;
  }

  
  .advisers-container .advisers-team {
    width: 94%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 50% 50%;
    grid-template-columns: 40% 40%;
    // justify-content: center;
    text-align: center;
    justify-content: center;
    gap: 5rem;
    margin-bottom: 20rem;
    // flex-direction: row;
  }
  
  .advisers-page-header {
    display: flex;
    margin-top: 50px;
    margin-left: 8%;
    font-size: 60px;
    font-weight: bolder;
    color: #648eb3 !important;
    filter: blur(1px);
    margin-bottom:10px
}

  .advisers-container .advisers-team .advisers-member {
    height: 85% ;
    width: 100%;
    margin: 1rem;
    background: rgb(220, 220, 220);
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    padding: 2rem;
    img{
      border-radius: 50%;
    }
  }
  
  .advisers-container .advisers-team .advisers-member img {
    width: 40%;
  }
  
  .advisers-container .advisers-team .advisers-member h2 {
    color: #444;
  }
  
  .advisers-container .advisers-team .advisers-member span {
    font-size: 1.1rem ;
    color: #999;
    font-weight: 400;
  }
  
  .advisers-container .advisers-team .advisers-member p {
    margin: 1.1rem 0;
    font-weight: 300;
    color: rgb(65, 64, 64);
    font-size: 1.2rem;
    text-align: justify;
  }
   
  .advisers-container .advisers-team .advisers-member .advisers-btn a {
    background: #ddd;
    display: block;
    float: right;
    width: 20%;
    margin: 0 10px;
    padding: 10px;
    border-radius: 6px;
    color: #444;
    text-transform: capitalize;
    transition: all 0.3s ease;
      font-size: 1.1rem;
  }
  
  .advisers-container .advisers-team .advisers-member .advisers-btn a:hover {
    background: #5a36dd;
    color: #fff;
  }
