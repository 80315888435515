.payment-footer-container {
  display: flex;
  margin: 0 auto;
  .payment-footer-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  li {
    margin: 2rem;
    list-style-type: none;
  }
  .payment-footer-img {
    width: 100px;
    height: 60px;
    border-radius: 0;
  }
  .paymentFooter-img {
    width: 150px;
  }
}

@media only screen and (max-width: 667px) {
  .payment-footer-container {
    display: none;
  }
}
