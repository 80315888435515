.edit-user-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.716);
  z-index: 999;
  padding: 20px 220px 20px 20px;
  height: 1100px;
}
.edit-user-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.edit-user-modal-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 15%;
  padding: 20px;
  background: #141b2d;
  border-radius: 12px;
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-user-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.edit-user-buttons-group {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  button {
    font-size: 12px;
  }
}

.edit-user-textField input[type="text"] {
  padding: 12px;
  margin: 12px;
  background-color: rgb(244, 243, 243);
  // border: 1px solid gray;
  border-radius: 2px;
}
.edit-user-button-wrap {
  display: flex;
  justify-content: center;
}
.achievemnt-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  p {
    padding-bottom: 1rem;
  }
}
.areYouSureModal {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 20%;
  text-align: center;
  background-color: #141b2d;
  top: 55%;
  z-index: 9999;
  h2 {
    color: white;
  }
  h3 {
    color: white;
    margin-top: 0.5rem;
  }
}
.areYouSureModalBtnContainer {
  display: flex;
  justify-content: space-evenly;
}
