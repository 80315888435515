.personal-details-page-root-container-parent-root {
  background: rgba(0, 0, 0, 0.634) url("../../../public/backgorund.jpg")
    no-repeat center center fixed;
  background-blend-mode: multiply;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 120vh;
  overflow-y: auto;
}
.personal-details-page-root-container-parent {
  width: 100%;
  background: #131722;
  position: relative;
  height: 1300px;
  z-index: 9999;

  .personal-details-page-root-container {
    width: 35%;
    height: 75%;
    margin: 0 auto;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background-color: #07232e;
    padding-top: 15px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid rgb(83, 83, 83);
    padding-left: 1rem;
    padding-right: 1rem;
    @media only screen and (max-width: 667px) {
      width: 80%;
      height: 80%;
    }
    .personal-details-page-container-header {
      height: 5%;
      text-align: left;
      color: white;
      font-size: 14;
      border-bottom: 1px solid rgba(226, 232, 245, 0.1);
      padding-bottom: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .personal-details-page-information-wrapper {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .MuiFormControl-root {
      .MuiTypography-root {
        color: white !important;
      }
      label {
        color: white;
      }

      .before-component {
        color: white !important;
      }

      .MuiInputLabel-root {
        &::before {
          color: black !important;
        }
      }
      .MuiInput-root {
        //   color: white;
        margin-bottom: 20px !important;
        &:focus {
          outline: none;
          border: none;
          border-bottom: 0.1rem solid #08b786;
        }

        &:after {
          border-bottom: 2px solid #08b786;
        }
      }

      .personal-details-page-payment-method-button {
        margin-top: 30px !important;
        background-color: #08b786 !important;
        &:hover {
          background-color: #087959 !important;
        }
      }
    }
  }
  .warning-message {
    color: red;
    font-size: 0.7rem;
    margin: 1rem auto;
    text-align: center;
  }
}
