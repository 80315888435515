.withdraws-history-container-admin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .withdraws-history-header {
        color: rgb(225, 221, 221);
        text-align: center;
        margin-bottom: 20px;
        padding: 10px;
    }
    .withdraw-history-table-header-row {
        background: linear-gradient(1deg, rgb(61 61 61 / 87%) 0%, rgba(228, 207, 249, 0) 100%);
        .withdraws-table-header-cell {
            font-weight: bolder;
            font-size: 1rem;
        }
    }
}