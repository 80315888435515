.how-it-works-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  // background: url("../../../public/how-it-works.png");
  //   background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5%;
  padding-bottom: 5%;
}

.how-it-works-page-header {
  // font-family: "Press Start 2P", cursive;
  display: flex;
  margin-top: 50px;
  margin-left: 8%;
  font-size: 60px;
  font-weight: bolder;
  color: rgb(179, 179, 179) !important;
  filter: blur(1px);
}

.how-it-works-page-textBox {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-top: 50px;
  position: relative;
  text-align: center;
}
.how-it-works-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}

.how-it-works-page-span {
  font-size: 1.5rem;
  // font-family: "Open Sans", sans-serif;
  color: rgb(179, 179, 179);
  text-shadow: 1px 1px 4px black;
  line-height: 1.5;
  margin-bottom: 10px;
}

.how-it-works-page-imagebox {
  margin-right: 100px;
  z-index: 99;
  display: none;
}

.how-it-works-page-imagebox2 {
  position: absolute;
  right: 0;
  top: -9%;
  z-index: -1;
  height: 100px;
}

.personal-details-container::after {
  content: "";
  background-image: url("../../../public/arrow-curved.svg");
  display: block;
  position: absolute;
  left: 80%;
  bottom: -40%;
  height: 40%;

  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.verify-account-container::after {
  content: "";
  background-image: url("../../../public/arrow-curved-long.svg");
  display: block;
  position: absolute;
  bottom: -30%;
  left: 95%;
  height: 90%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.how-it-works-types-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  z-index: 9;
}
.how-it-works-types-single-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}

.how-it-works-types-headers {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 1px 1px 4px black;
  padding: 20px 0;
  color: white;
}

.how-it-works-types-spans {
  font-size: 18px;
  // font-family: "Open Sans", sans-serif;
  color: rgb(179, 179, 179);
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .how-it-works-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .how-it-works-types-single-container {
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .personal-details-container::after {
    content: "";
    display: none;
    position: absolute;
    bottom: -15%;
    left: 100%;
    height: 65%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .verify-account-container::after {
    display: none;
  }

  .how-it-works-page-header {
    font-size: 2.5rem;
  }

  .how-it-works-page-textBox {
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    // margin-top: 50px;
    position: relative;
    text-align: center;
  }
  .how-it-works-page-h3 {
    color: white;
    text-shadow: 1px 1px 4px black;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .how-it-works-page-span {
    font-size: 18px;
    text-align: center;
    z-index: 11;
    width: 90%;
    margin-top: 50px;
    margin: 0 auto;
  }

  .how-it-works-page-imagebox {
    z-index: 99;
    display: none;
  }

  .how-it-works-page-imagebox2 {
    height: 100px;
  }

  .how-it-works-types-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
    z-index: 9;
    margin: 0 auto;
    margin-top: 40px;

    div {
      margin-bottom: 30px;
    }
  }

  .how-it-works-types-headers {
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 1px 1px 4px black;
    color: white;
    font-size: 16px;
    padding-top: 2px;
    text-align: center;
  }

  .how-it-works-types-spans {
    font-size: 14px;
    // font-family: "Open Sans", sans-serif;
    color: rgb(179, 179, 179);
    font-weight: bold;
    text-shadow: 1px 1px 3px black;
    text-align: center;
  }
}
