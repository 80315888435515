.users-table-header-row {
  background: #3e4396;

  .users-table-header-cell {
    font-size: 0.8rem;
    font-weight: bolder;
  }
}
.users-table-head {
  background: #3e4396;
}
.edit-user-textField {
  color: black;
}
.edit-user-textField input[type="text"] {
  background-color: #1f2a40;
  // margin: 0.8rem
  margin: 0;
  padding: 0;
  // border: none;
  height: 36px;
}
