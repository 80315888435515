.withdraw-table-root-container {
  .MuiTableCell-head {
    background-color: #131722 !important;
  }
  max-height: 300px;
  overflow-y: auto;
  // border: 2px solid grey !important;
  // border-radius: 4px !important;
  .withdraw-table-cell {
    font-size: 0.7rem;
    color: rgb(97 131 143);
  }
}
.withdraw-history-table-cell {
  color: white;
  font-weight: bolder;
}
