.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.716);
  z-index: 999;
  padding: 20px 220px 20px 20px;
  .modal {
    margin: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modalInfo {
      display: flex;
      flex-direction: column;
      width: 80%;
      padding: 20px;
      background: #141B2D;
      border-radius: 12px;
      .editOrderTop {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .buttonWrap {
        display: flex;
        justify-content: center;
      }
    }
  }
}
