.admin-affiliate {
  background-color: #141b2d;
  position: relative;
  top: 0;
  border-left: 1px solid white;
  border-bottom: 1px solid white;

  height: 110vh;
  h2 {
    color: rgb(225, 230, 225);
    text-align: center;
    font-size: 1.8rem;
    padding: 10px;
  }
  .admin-affiliate-cards-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-around;
    .admin-affiliate-card {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 20%;
      height: 80px;
      align-items: center;
      font-size: 1rem;
      color: rgb(225, 221, 221);
      background: rgb(96, 96, 96);
      border: 1px solid rgb(255, 255, 255);
    }
  }
  .admin-affiliate-table {
    width: 90%;
    margin: 4rem auto;
  }
}
